import { memo, useEffect } from "react"
import queryString from "query-string"
import { downloadFile } from "../../utils/fetch"

// We don't want to use the use-query-params hook here to keep this page as light as possible
function extractQueryParam (queryParam) {
  return queryString.parse(window.location.search.replace(`?`, ``))[queryParam]
}

function EmbeddedDownloadPage () {
  useEffect(() => {
    const fileUrl = extractQueryParam(`fileUrl`)
    const fileName = extractQueryParam(`fileName`)

    downloadFile(fileUrl, fileName)
  }, [])

  // User never shows this
  return `Downloading...`
}

export default memo(EmbeddedDownloadPage)
